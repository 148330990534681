<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    chartData: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      options: {
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  methods: {
    renderBarChart() {
      this.renderChart(
        {
          labels: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
          datasets: this.chartData,
        },
        this.options
      );
    },
  },

  watch: {
    chartData() {
      this.renderBarChart();
    },
  },

  mounted() {
    this.renderBarChart();
  },
};
</script>
