<template>
  <div v-if="seller != null">
    <div class="bg-secondary-200 border border-secondary-300 px-4 py-3 flex flex-col">
      <div class="border-b border-secondary-300 mb-3 text-lg font-semibold font-serif">{{ $t('sales_company.sales_personal_alarm')}}</div>
      <SellerAlarmChart v-if="sellerAlarmsData != null" :chartData="sellerAlarmsData" />
    </div>

    <div class="flex flex-wrap mt-4">
      <div class="w-full lg:w-1/2 lg:pr-3 mb-3">
        <div class="bg-secondary-200 border border-secondary-300 px-4 py-3">
          <div class="border-b border-secondary-300 mb-3 text-lg font-semibold font-serif">{{ $t('sales_company.revenue')}}</div>
          <SellerRevenueChart v-if="sellerRevenueData != null" :chartData="sellerRevenueData" :labels="chartLabels" :legend="legend" :centerText="sellerRevenueGoal" />
        </div>
      </div>
      <div class="w-full lg:w-1/2 flex flex-col mb-3">
        <div class="bg-secondary-200 border border-secondary-300 px-4 py-3 mb-3">
          <div class="border-b border-secondary-300 mb-3 text-lg font-semibold font-serif">{{ $t('sales_company.other_products')}}</div>
          <div class="flex flex-wrap" v-bind:class="type == 'company' ? 'py-1' : 'py-11'">
            <div class="w-1/2 flex flex-col text-center">
              <div class="text-primary-500 font-bold text-3xl mb-2">{{ agreement_srt330 }}</div>
              <div class="font-bold mb-1 font-serif text-xl">SRT 330</div>
            </div>
            <div class="w-1/2 flex flex-col text-center">
              <div class="text-primary-500 font-bold text-3xl mb-2">{{ agreement_srt334 }}</div>
              <div class="font-bold mb-1 font-serif text-xl">SRT 334</div>
            </div>
          </div>
        </div>
        <div class="bg-secondary-200 border border-secondary-300 px-4 py-3 mb-3">
          <div class="border-b border-secondary-300 mb-3 text-lg font-semibold font-serif">{{ $t('sales_company.sales_since_launch')}}</div>
          <div class="flex flex-wrap" v-bind:class="type == 'company' ? 'py-1' : 'py-10'">
            <div class="w-1/3 flex flex-col text-center">
              <div class="text-primary-500 font-bold text-2xl mb-2">{{ alarm_since_start }}</div>
              <div class="font-bold mb-1 font-serif text-lg">Larm</div>
            </div>
            <div class="w-1/3 flex flex-col text-center">
              <div class="text-primary-500 font-bold text-2xl mb-2">{{ srt330_since_start }}</div>
              <div class="font-bold mb-1 font-serif text-lg">SRT 330</div>
            </div>
            <div class="w-1/3 flex flex-col text-center">
              <div class="text-primary-500 font-bold text-2xl mb-2">{{ srt334_since_start }}</div>
              <div class="font-bold mb-1 font-serif text-lg">SRT 334</div>
            </div>
          </div>
        </div>
        <div v-if="type == 'company'" class="bg-secondary-200 border border-secondary-300 px-4 py-3 mb-3">
          <div class="border-b border-secondary-300 mb-3 text-lg font-semibold font-serif">{{ $t('sales_company.sales_since_inception')}} sedan start</div>
          <div class="flex flex-wrap" v-bind:class="type == 'company' ? 'py-1' : 'py-10'">
            <div class="w-1/3 flex flex-col text-center">
              <div class="text-primary-500 font-bold text-2xl mb-2">{{ reseller_alarm_since_start }}</div>
              <div class="font-bold mb-1 font-serif text-lg">Larm</div>
            </div>
            <div class="w-1/3 flex flex-col text-center">
              <div class="text-primary-500 font-bold text-2xl mb-2">{{ reseller_srt330_since_start }}</div>
              <div class="font-bold mb-1 font-serif text-lg">SRT 330</div>
            </div>
            <div class="w-1/3 flex flex-col text-center">
              <div class="text-primary-500 font-bold text-2xl mb-2">{{ reseller_srt334_since_start }}</div>
              <div class="font-bold mb-1 font-serif text-lg">SRT 334</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SellerAlarmChart from "@/components/seller_alarm_chart";
import SellerRevenueChart from "@/components/doughnut_chart";

export default {
  name: "Sales",
  title() {
    return `Försäljning | SecurCloud`;
  },
  components: {
    SellerAlarmChart,
    SellerRevenueChart,
  },
  data() {
    return {
      user: this.$store.state.user,
      type: this.$route.params.type,
      id: this.$route.params.id,
      chartLabels: ["Revenue generated", "Remaining goal"],
      legend: {},
      seller: null,
      sales_goal: null,
      agreement_srt330: 0,
      agreement_srt334: 0,
      alarm_since_start: 0,
      srt330_since_start: 0,
      srt334_since_start: 0,
      reseller_alarm_since_start: 0,
      reseller_srt330_since_start: 0,
      reseller_srt334_since_start: 0,
      sellerAlarmsData: null,
      sellerRevenueData: null,
      sellerRevenueGoal: "",
    };
  },
  methods: {
    async getSellerSales() {
      try {
        let apiUrl = "";

        if (this.type == "seller") apiUrl = `${process.env.VUE_APP_SERVER_URL}/sales/seller/${this.id}`;
        else if (this.type == "company" && this.user.role == "admin") apiUrl = `${process.env.VUE_APP_SERVER_URL}/sales/company`;
        else return;

        let response = await this.axios.get(apiUrl);
        this.seller = response.data.seller;
        this.sales_goal = response.data.sales_goal;

        this.agreement_srt330 = response.data.agreement_srt330;
        this.agreement_srt334 = response.data.agreement_srt334;
        this.alarm_since_start = response.data.alarm_since_start;
        this.srt330_since_start = response.data.srt330_since_start;
        this.srt334_since_start = response.data.srt334_since_start;

        if (this.type == "company") {
          this.reseller_alarm_since_start = response.data.reseller_alarm_since_start;
          this.reseller_srt330_since_start = response.data.reseller_srt330_since_start;
          this.reseller_srt334_since_start = response.data.reseller_srt334_since_start;
        }

        if (this.user.role == "seller" && this.type == "seller") this.setPageTitle(`${this.seller.name} - Försäljning`, "sales");
        else if (this.type == "company" && this.user.role == "admin") this.setPageTitle(`Företagets Försäljning`, "sales");

        let companyMonthlyAlarms = _.map(this.sales_goal.monthly_budget, "alarms");
        let sellerExpectedMonthlyAlarms = _.map(this.seller.monthly_budget, "alarms");

        let alarmsData = [
          {
            type: "bar",
            label: "Företag",
            data: companyMonthlyAlarms,
            backgroundColor: "#5CF59D",
            categoryPercentage: 0.7,
            barPercentage: 1,
          },
          {
            type: "bar",
            label: `${this.seller.name} (Expected)`,
            data: sellerExpectedMonthlyAlarms,
            backgroundColor: "#3399FF",
            categoryPercentage: 0.7,
            barPercentage: 1,
          },
          {
            type: "bar",
            label: `${this.seller.name} (Sold)`,
            data: response.data.alarm_sold_monthly,
            backgroundColor: "#00C16E",
            categoryPercentage: 0.7,
            barPercentage: 1,
          },
          // {
          //   type: 'line',
          //   label: `Median (Sold)`,
          //   data: sellerExpectedMonthlyAlarms,
          //   fill: false,
          //   borderColor: 'yellow',
          //   tension: 0.2
          // }
        ];
        this.sellerAlarmsData = alarmsData;

        let sellerRemainingRevenue = 0;
        if (response.data.total_revenue_generated < this.seller.yearly_budget_revenue) sellerRemainingRevenue = this.seller.yearly_budget_revenue - response.data.total_revenue_generated;
        let revenueData = [
          {
            data: [response.data.total_revenue_generated, sellerRemainingRevenue],
            backgroundColor: ["#00C16E", "#E8E8E8"],
          },
        ];

        this.sellerRevenueGoal = this.currency(this.seller.yearly_budget_revenue);
        this.sellerRevenueData = revenueData;
      } catch (error) {
        this.handleError(error);
      }
    },

    currency: function(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " kr";
    },
  },

  created() {
    this.getSellerSales();
  },
};
</script>
